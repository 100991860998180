@import "../theme";

.app-container {
  height: 100%;
  @media @mobile {
    height: auto;
  }
  .back-component-container {
    padding: 20px 24px;
    line-height: 1;
    border: 1px solid #a0a0a0;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  .admin-layout-container {
    flex: 1;
    width: 100%;
    height: 100%;
    &.admin-active {
      flex: 0 0 calc(100% - 64px);
      height: calc(100% - 64px);
    }
    aside {
      flex: 0 0 270px;
      height: 100%;
      background-color: #fff;
      border-right: 1px solid #e0e0e0;
      position: relative;
      a {
        width: 100%;
        height: 72px;
        flex: 0 0 72px;
        padding: 24px 0 24px 24px;
        box-shadow: inset 0px -1px 0px #eaeaec;
      }
      .admin-user-container {
        border-top: 1px solid #e0e0e0;
        width: 100%;
        min-height: 64px;
        height: auto;
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        .ant-collapse {
          border: 0;
          & > .ant-collapse-item:last-child {
            border: 0;
          }
          .ant-collapse-header {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            padding: 20px 24px;
            background-color: #fff;
            &:before,
            &:after {
              display: none;
            }
            .ant-collapse-arrow {
              position: relative;
              top: 5px;
              padding-top: 0;
            }
          }
          .ant-collapse-content {
            border-top: 0;
          }
          .ant-collapse-content {
            .ant-collapse-content-box {
              padding: 0;
              a,
              span {
                width: 100%;
                display: block;
                height: 48px;
                line-height: 48px;
                padding: 0 0 0 64px;
                text-align: left;
                box-shadow: none;
                color: #696969;
                &.active,
                &:hover {
                  background-color: #dfffed;
                  font-weight: 900;
                  font-family: RobotoBold;
                  color: #000;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .user-wrap {
          font-size: 16px;
          color: #696969;
          img {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            border-radius: 24px;
          }
        }
      }
      .menu-layout-container {
        flex: 0 0 calc(100% - 136px);
        margin-bottom: 64px;
        .red-dot {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: red;
          border-radius: 50%;
          position: relative;
          top: 2px;
          margin-left: 8px;
        }
      }
    }
    main {
      &.content-main {
        padding: 0 40px 40px;
        .common-container {
          margin-top: 40px;
        }
        @media @mobile {
          padding: 0 30px / @pv 30px / @pv;
          .common-container{
            margin-top: 30px / @pv;
          }
        }
        &.msg {
          padding: 0;
        }
        height: 100%;
        background-color: #f9f9f9;
        h1 {
          font-size: 48px;
          line-height: 58px;
          font-weight: 700;
          margin-bottom: 24px;
        }
        h2 {
          font-weight: 700;
          line-height: 38px;
          margin-bottom: 24px;
        }
        h4 {
          margin-bottom: 24px;
          line-height: 26px;
          font-weight: normal;
        }
      }
    }
    .ant-steps {
      margin-bottom: 24px;
      .ant-steps-item-active {
        .ant-steps-item-icon {
          background-color: #00ff6d;
          color: #000c17;
          border-color: #00ff6d;
          .ant-steps-icon {
            font-weight: 700;
            color: inherit;
          }
        }
        .ant-steps-item-title {
          font-weight: 700;
          letter-spacing: 1px;
        }
      }
      .ant-steps-item-wait {
        @media @mobile {
          flex: 0.4;
          .ant-steps-item-title {
            display: none;
          }
        }
      }
    }
    .ant-form {
      width: 100%;
    }
  }

  .customize-page {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 32px;
    text-align: right;
  }
  .access-container {
    height: 64px;
    flex: 0 0 64px;
    line-height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    width: 100%;
    background-color: #dddfe4;
  }

}

