@import "@/styles/theme.less";

.transaction-container {
    .search-container {
        margin-bottom: 31px;

        .field-form-items {
            height      : 56px;
            margin-right: 16px;
        }

        .filter-item {
            width      : 56px;
            height     : 56px;
            flex       : 0 0 56px;
            border     : 1px solid #e0e0e0;
            margin-left: 16px;
            cursor     : pointer;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .clear-all {
            margin-left    : 18px;
            text-transform : uppercase;
            font-size      : 14px;
            font-weight    : 900;
            text-decoration: underline;
            cursor         : pointer;
        }
    }

    .ant-pagination-total-text {
        position   : absolute;
        left       : 32px;
        color      : #000;
        font-weight: 700;
    }
}