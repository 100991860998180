@import "../theme";

.donation-container {
  padding-top: 80px;
  background-color: #f1f1f6;
  @media @desktop {
    padding: 80px 140px 0;
    margin: 0 -140px;
  }
  @media @mobile {
    padding: 24px / @pv 32px / @pv;
  }
  h1 {
    font-size: 48px;
    line-height: 58px;
    @media @mobile {
      font-size: 32px / @pv;
      line-height: 42px / @pv;
    }
  }
  & > h2 {
    max-width: 1000px;
    margin-bottom: 56px;
    font-size: 16px;
    font-weight: normal !important;
    font-family: Roboto;
    color: #000000D9;
    @media @mobile {
      width: 100%;
      font-size: 16px / @pv;
      line-height: 26px / @pv;
      margin-bottom: 32px / @pv;
    }
  }
  .ant-steps {
    width: 362px;
    margin-bottom: 32px;
    .ant-steps-item-icon {
      width: 26px;
      height: 26px;
      line-height: 26px;
    }
    .ant-steps-item-title {
      line-height: 26px;
      &:after {
        top: 12px;
      }
    }
    .ant-steps-item-active {
      .ant-steps-item-icon {
        background-color: #00ff6d;
        color: #000c17;
        border-color: #00ff6d;
        .ant-steps-icon {
          font-weight: 700;
          color: inherit;
        }
      }
      .ant-steps-item-title {
        font-weight: 900;
        letter-spacing: 1px;
        font-size: 16px;
      }
    }
    @media @mobile {
      width: 100%;
      margin-bottom: 24px / @pv;
      .ant-steps-item-icon {
        width: 42px / @pv;
        height: 42px / @pv;
        line-height: 40px / @pv;
      }
      .ant-steps-item-title {
        line-height: 42px;
        &:after {
          top: 20px / @pv;
        }
      }
      .ant-steps-item:not(.ant-steps-item-active) {
        .ant-steps-item-content {
          display: none;
        }
        flex: 0 0 60px / @pv;
      }
    }
  }
  .donation-info {
    @media @mobile {
      width: 100%;
      flex-flow: column nowrap;
    }
    .donation-items-container {
      margin-bottom: 80px;
      flex: 1 0 648px;
      .card-wrap {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
        padding: 32px;
      }
      @media @mobile {
        width: 100%;
        margin-bottom: 24px / @pv;
        .card-wrap {
          padding: 16px / @pv;
        }
      }
    }
    .card-description {
      margin-top: 32px;
      font-size: 12px;
      line-height: 20px;
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
      @media @mobile {
        margin-top: 16px / @pv;
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }

    h2 {
      margin-bottom: 24px;
      line-height: 38px;
      font-size: 24px;
      font-family: RobotoBold;
      font-weight: 900;
    }
    .ant-radio-group {
      @media @desktop {
        margin-bottom: 24px;
      }
      label {
        padding: 15px 24px;
        height: auto;
        line-height: 1;
        @media @desktop {
          &:not(:last-child) {
            margin-right: 14px;
          }
        }
        &.ant-radio-button-wrapper-checked {
          &:before {
            width: 0;
          }
          border: 2px solid #00ff6d;
          background: #dfffed;
          color: #000;
          font-weight: 900;
        }
        @media @mobile {
          width: calc(33% - 11px);
          padding: 16px / @pv 0;
          text-align: center;
          &:not(:nth-of-type(3n)) {
            margin: 0 16px / @pv 16px / @pv 0 !important;
          }
        }
      }
      @media @mobile {
        width: 100%;
        &.frequency-radio {
          label {
            width: auto;
            flex: 0 0 auto;
            padding: 16px / @pv;
            &:last-child {
              margin-right: 0 !important;
            }
          }
        }
      }
    }
    .field-form-items {
      width: 100%;
      margin-bottom: 24px;
      &.field-required {
        & > span:first-child {
          &:after {
            display: inline-block;
            margin-left: 8px;
            width: 12px;
            height: 12px;
            line-height: 12px;
            content: "*";
            color: #ff4d4f;
          }
        }
      }
    }
    .ant-checkbox-wrapper {
      font-size: 16px;
    }
    .txt_12 {
      line-height: 20px;
      font-size: 12px;
      color: #a0a0a0;
    }

    .donation-pay-container {
      flex: 0 0 670px;
      background-color: #fff;
      padding: 32px;
      margin-bottom: 80px;
      border: 1px solid #e0e0e0;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
      .ant-picker {
        padding-bottom: 0;
      }
      .row-flex {
        .ant-form-item {
          &:first-child {
            margin-right: 24px;
          }
          flex: 1;
          &.select-item {
            padding-right: 0;
            //padding-left: 0;
            padding-bottom: 3px;
            //.ant-form-item-label,
            //.ant-form-item-control {
            //  padding-left: 16px;
            //}
          }
        }
      }
      .ant-radio-group {
        display: flex;
        width: 100%;
        color: #696969;
        label.ant-radio-button-wrapper {
          flex: 1;
          span:last-child {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            svg {
              margin-bottom: 8px;
            }
          }
        }
        @media @mobile {
          &.card-radio {
            flex-wrap: wrap;
            label {
              width: calc(50% - 10px);
              flex: 0 0 calc(50% - 10px);
              height: 80px / @pv;
              &:not(:nth-of-type(2n)) {
                margin: 0 16px / @pv 16px / @pv 0 !important;
              }
              &:nth-of-type(2n) {
                margin: 0 !important;
              }
            }
          }
        }
      }
      @media @mobile {
        width: 100%;
        flex: 0 0 auto;
        padding: 16px / @pv;
        h2 {
          font-size: 20px / @pv;
        }
        form {
          .flex {
            flex-flow: column nowrap;
            & > .field-form-items,
            & > .ant-form-item {
              width: 100%;
              margin-right: 0;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .donation-card-container {
    flex: 0 0 470px;
    background-color: #fff;
    padding: 32px;
    margin-left: 30px;
    margin-bottom: 30px;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    @media @mobile {
      flex: 0 0 auto;
      margin-left: 0;
      width: 100%;
      padding: 16px / @pv;
    }
    img {
      width: 160px;
      height: 160px;
      margin-bottom: 24px;
    }
    h1 {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 8px;
    }
    h4 {
      font-size: 16px;
      line-height: 26px;
      color: #696969;
      margin-bottom: 0;
    }
    .total-item {
      font-weight: 900;
      margin-bottom: 36px;
      span:last-child {
        font-family: RobotoBold;
      }
      @media @mobile {
        font-family: Roboto;
        color: #000;
        font-weight: normal;
        span:last-child {
          font-size: 24px / @pv;
        }
      }
    }
    button {
      margin-bottom: 24px;
    }
    .donation-tips {
      span {
        font-size: 12px;
        color: #696969;
      }
      img {
        height: 32px;
        width: auto;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
    .tips-item {
      font-size: 12px;
      color: #696969;
      .anticon {
        font-size: 14px;
        color: #05a5ff;
        margin-right: 4px;
        margin-bottom: 2px;
      }
    }
    #paypal-button-wrapper {
      &.hidden {
        display: none;
      }
      iframe {
      }
    }
  }
}

.donation-success-container {
  img {
    width: 130px;
    margin-bottom: 56px;
    margin-top: 120px;
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
  }
  button {
    margin-bottom: 24px;
  }
  a {
    font-weight: 900;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #000;
  }
  @media @mobile {
    padding: 32px / @pv;
    img {
      margin: 24px / @pv auto;
      width: 130px / @pv;
    }
    h1 {
      font-size: 32px / @pv;
      line-height: 42px / @pv;
      text-align: center;
      margin-bottom: 8px / @pv;
    }
    button {
      width: 100%;
    }
  }
}
