@import '@/styles/theme';
.income-archived-history {
    .search-container {
        width: 100%;
        margin-bottom: 26px;
        .field-form-items{
            margin: 0 8px;
            &:first-child {
                margin-left: 0;
            }
            @media @mobile {
                width: 100%;
                margin: 0 0 12px / @pv;
            }
        }
        .ant-btn-primary {
            margin-left: auto;
        }
    } 
    .table-container {
        width: 100%;

        .ant-table-summary {
            .ant-table-cell {
                font-weight: bold;
            }
        }
    }  
}