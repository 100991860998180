@import "../theme";

.income-overview-container {
  .field-form-items {
    @media @mobile {
      width: 100%;
    }
  }
  .card-wrap {
    margin-top: 24px;
    width: 100%;
    .flex {
      width: 100%;
      .flex-1 {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
        margin-bottom: 40px;
        &.campaign-card-container {
          margin-right: 0 !important;
        }
        &:first-child {
          margin-right: 40px;
        }
        width: 40%;
        overflow: hidden;
      }
      &.card-row {
        @media @mobile {
          display: block;
          .flex-1 {
            width: 100%;
            .no-data {
              padding: 2px / @pv 30px / @pv;
            }

            .card-item-container {
              height: 460px;
            }
          }
        }
      }
    }
    .card-item-title {
      .ant-dropdown-trigger {
        display: flex;
      }
    }
  }
  .grant-card-container {
    h2 {
      margin-bottom: 16px !important;
    }
    ul {
      padding-left: 18px;
      color: #a0a0a0;
      font-size: 14px;
      list-style-type: circle;
      margin-bottom: 16px;
      li {
        line-height: 24px;
        color: #696969;
        span {
          color: #a0a0a0;
          display: inline-block;
          width: 100px;
        }
      }
    }
    p {
      margin-bottom: 0;
      color: #a0a0a0;
      font-size: 12px;
    }
  }
  .fund-card-container {
    .table-wrap {
      height: calc(100% - 58px);
      .ant-empty-normal {
        margin: 23px 0;
      }
    }
  }
  .no-data {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    svg {
      width: 32px;
      height: 32px;
      color: #e0e0e0;
      margin-bottom: 16px;
    }
    h4 {
      margin-bottom: 6px !important;
      color: #000;
      font-weight: bolder;
      font-size: 14px;
    }
    p {
      margin-bottom: 0;
      color: #a0a0a0;
      font-size: 12px;
    }
    a {
      margin-top: 16px;
      color: #000;
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: underline;
      font-weight: 900;
      letter-spacing: 1px;
    }
  }
  @media @mobile {
    .card-wrap {
      .card-item-title {
        padding: 0 16px / @pv !important;
        .ant-dropdown-trigger {
          display: flex;
        }
      }
      .fund-card-container {
        .ant-table-title {
          display: none;
        }
        .ant-table {
          border: 0;
        }
      }
    }
  }
}

.income-detail-container {
  height: 100%;
  flex: 1;
  // overflow: hidden;
  display: block;
  .search-container {
    @media @mobile {
      width: 100%;
    }
  }
  .table-wrap {
    width: 100%;
    flex: 1 1 calc(100% - 326px);
    height: calc(100% - 326px);
    padding: 0;
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    .ant-list {
      height: auto;
      position: relative;
      .ant-spin-container {
      }
    }
    @media @mobile {
      .ant-table {
        border: 0;
      }
      .ant-pagination {
        margin-right: 16px / @pv;
      }
    }
  }
}
