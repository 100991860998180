.deactived-container{
    height: 90%;
    .contents{
        justify-content: center;
        align-items: center;
        flex: 1;
        .box {
            width: 60%;
            text-align: center;
        }
    }
}