@import '@/styles/theme';
.order-view-container {
    .order-header {
        margin-bottom: 16px;
        h2 {
          font-size: 40px;
          font-weight: 900;
          margin-bottom: 0 !important;
          line-height: 50px;
          position: relative;
          .ant-tag {
            margin-left: 16px;
            @media @mobile {
                position: absolute;
                transform: translate(0, 100%);
                top: -4em;
                left: -1em;
            }
          }
        }
        .filter-item {
          justify-content: center;
          align-items: center;
          display: flex;
          width: 56px;
          height: 56px;
          flex: 0 0 56px;
          border: 1px solid #e0e0e0;
          margin-left: 16px;
          cursor: pointer;
        }
      }
      .des {
        margin-top: 18px;
        margin-bottom: 40px;
        color: #3b5266;
        max-width: 670px;
      }
      .card-wrap {
        flex: 1;
        padding: 32px;
        margin-bottom: 24px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
        h3 {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 0;
        }
        p.flex {
          align-items: flex-start;
          span {
            display: inline-block;
            max-width: 70%;
            text-align: right;
            white-space: normal;
            word-break: break-word;
          }
        }
      }
      .flex {
        & > .card-wrap:not(:last-child) {
          margin-right: 40px;
        }
      }
      .field-form-items {
        width: 100%;
        margin-bottom: 40px;
      }
}