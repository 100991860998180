.application-review {
    color: #313335;
    width: 100%;
    padding: 35px 45px;
    .top-bar {
        position: relative;
        h1 {
            font-size: 40px;
            padding-right: 20px;
            line-height: 50px;
        }
        .ant-tag {
            position: relative;
            top: -18px;
        }
    }
    .des {
        color: #4A4A4A;
        font-size: 16px;
        width: 60%;
    }
    .sub-infos {
        background-color: white;
        border: 1px solid #DDDFE4;
        box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
        padding: 32px;
        width: 100%;
        margin-top: 40px;
        div>p {
            min-height: 25px;
        }
        .sub-title {
            font-size: 24px;
            color: #000;
        }
        .key, .val {
            max-width: 24%;
            width: 24%;
            font-size: 17px;
            margin-bottom: 30px;
        }
        .key {
            color: #797F84;
        }
        .key:nth-child(even) {
            padding-left: 20px;
        }
        .val {
            text-align: right;
            a {
                color: #000;
                text-decoration: underline;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
    .documents,.artist-notes {
        margin-top: 10px;
    }
    .documents {
        .doc {
            border: 1px solid #DDDFE4;
            padding: 15px;
            width: 45%;
            margin-bottom: 10px;
            a{
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            img {
                display: inline-block;
                margin-left: 10px;
                cursor: pointer;
                margin: 0 20px;
                &:last-child{
                    margin-left: auto;
                }
            }
        }
    }
    .admin-notes {
        cursor: pointer;
        height: 160px;
        .ant-input {
            height: 120px;
        }
    }

    .upload-item-wrap {
        width: 100%;
        margin-bottom: 24px;
        .ant-upload-select {
            width: 100%;
        }
        .children-upload {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          background: #f9f9f9;
          border: 1px dashed #e0e0e0;
          padding: 24px;
          cursor: pointer;
          .ant-upload-drag-icon {
            color: #00ff6d;
            font-size: 32px;
            margin-bottom: 16px;
          }
          .ant-upload-text {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .ant-upload-hint {
            color: #696969;
          }
        }
        .ant-upload-list {
          button {
            border: none;
          }
        }
      }
    .btns {
        margin-top: 30px;
        .ant-btn {
            margin-left: 20px;
        }
    }
}