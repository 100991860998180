@import "../theme";
.profile-list-container {
  width: 100%;

  h1 {
    margin-top: 80px;
    font-size: 48px;
    line-height: 58px;
    font-weight: 900;
    & + p {
      margin-top: 18px;
      max-width: 670px;
      text-align: center;
    }
  }
  .search-wrap {
    margin-top: 40px;
    width: 100%;
    padding: 32px;
    background-color: #fff;
    border: 1px solid #dddfe4;
    .field-form-items {
      margin-right: 26px;
      .item-label {
        color: #000;
      }
      .ant-input::placeholder, .ant-select-selection-placeholder {
        color: #777;
      }
      .ant-btn svg, .ant-select-arrow svg{
        color: #555;
      }
      &:first-child {
        flex: 3;
      }
      .ant-input-group .ant-input {
        width: 99%;
      }
      .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        left: -8px;
        padding: 0;
        border: 0;
        .anticon-search {
          font-size: 20px;
        }
      }
    }
    .clear-all {
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .search-text {
    width: 100%;
    margin-top: 16px;
    font-size: 12px;
    text-align: left;
    color: #333;
  }
  .result-wrap {
    width: 100%;
    color: #333;
    .ant-select-selection-item {
      font-weight: 900;
      font-size: 16px;
    }
  }
  .profile-list-wrap {
    width: 100%;
    margin-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-flow: row wrap;
    @media @desktop {
      min-height: 350px;
    }

    .profile-cell {
      padding: 24px 24px 64px 24px;
      background: #fff;
      border: 1px solid #dddfe4;
      width: calc(33.33% - 20px);
      flex: 0 0 calc(33.33% - 20px);
      margin-bottom: 30px;
      position: relative;
      a {
        color: #000;
      }
      &:not(:nth-of-type(3n)) {
        margin-right: 30px;
      }
      img {
        display: block;
        width: 100%;
        height: calc(100% - 225px);
        object-fit: cover;
        object-position: center;
      }
      h3 {
        margin-top: 24px;
        line-height: 38px;
        font-size: 24px;
        font-weight: 900;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 76px;
      }
      span {
        display: block;
        margin-top: 8px;
        width: 100%;
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 26px;

        &.ant-tag {
          width: auto;
          position: absolute;
          top: 32px;
          left: 42px;
        }
      }
      p {
        margin-top: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        height: 76px;
      }
      .link {
        margin-top: 24px;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #000;
      }
    }
  }
  .add-more {
    text-align: center;
    margin-bottom: 120px;
  }
  @media @mobile {
    padding: 0 32px / @pv;
    h1 {
      margin-top: 20px / @pv;
      margin-bottom: 16px / @pv;
      font-size: 40px / @pv;
      line-height: 50px / @pv;
      text-align: center;
      & + p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .search-wrap {
      flex-direction: column;
      padding: 16px / @pv;
      .field-form-items {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
      .clear-all {
        display: none;
      }
    }
    .result-wrap {
      white-space: nowrap;
      i {
        display: none;
      }
      .ant-select-selector {
      }
    }
    .profile-list-wrap {
      flex-direction: column;
      .profile-cell {
        flex: 0 0 1;
        width: 100%;
        margin-right: 0 !important;
        padding: 24px / @pv;
        position: relative;
        img {
          width: 267px / @pv;
          height: 267px / @pv;
        }
        h3 {
          margin-top: 24px / @pv;
          line-height: 38px / @pv;
          font-size: 24px / @pv;
        }
        span {
          margin-top: 8px / @pv;
          line-height: 26px / @pv;
        }
        p {
          margin-top: 8px / @pv;
          height: auto;
        }
        a {
          margin-top: 24px / @pv;
          font-size: 14px / @pv;
          line-height: 24px / @pv;
        }
      }
    }
  }
}
