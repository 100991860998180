@import '@/styles/theme';
.select-card-container {
    width: 100%;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid #e0e0e0;
  padding: 12px 16px 4px;
  margin-bottom: 24px;
  position: relative;
  label {
    text-transform: uppercase;
    color: #a0a0a0;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1;
  }
  .select-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    position: relative;
    &:hover {
      .anticon-close-circle {
        display: block;
      }
    }
    span {
      &:first-child {
        height: 28px;
        line-height: 28px;
      }
      &:last-child {
        font-size: 12px;
        color: #c2c2c2;
        transition: transform 0.2s;
        &.active {
          transform: rotate(-180deg);
        }
      }
      &.anticon-close-circle {
        display: none;
        position: absolute;
        right: 0;
        color: #c2c2c2;
      }
      i {
        color: #bfbfbf;
      }
    }
  }
  .selected-card-wrap {
    display: flex;
    
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 4px -16px 0;
    padding: 16px 16px 7px;
    border-top: 1px solid #e0e0e0;
    img {
      height: 108px;
      flex: 0 0 108px;
      margin-right: 24px;
    }
    .right-item {
      flex: 0 0 calc(100% - 132px);
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      h4 {
        display: flex;
        @media @mobile {
          display: block;
          a {
            display: block;
            margin-top: 12px / @pv;
          }
        }
        flex-flow: row nowrap;
        justify-content: space-between;
        line-height: 1 !important;
        margin-bottom: 11px !important;
        span {
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 12px;
          color: #000;
          font-weight: 900;
          cursor: pointer;
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 20px;
        color: #a0a0a0;
        list-style: circle;
        li {
          p {
            display: flex;
            margin-bottom: 4px;
          }
          span {
            display: inline-block;
            width: 68px;
            flex: 0 0 68px;
          }
          i {
            display: inline-block;
            line-height: 24px;
            white-space: normal;
          }
        }
      }
    }

    @media @mobile {
        display: block;
        img {
            margin: 6px 0;
        }
    }
  }
  .list-wrap {
    position: absolute;
    top: 57px;
    left: -1px;
    right: -1px;
    z-index: 1;
    background-color: #fff;
    height: auto;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16),
      4px 4px 8px rgba(0, 0, 0, 0.04);
    // display: none;
    opacity: 0;
    transition: opacity 1s;

    &.show {
      // display: block;
      opacity: 1;
    }
    .ant-input-affix-wrapper {
      padding: 12px 24px;
      border-bottom: 1px solid #e0e0e0;
      span {
        color: #696969;
      }
    }

    .ReactVirtualized__List {
      outline: none;
    }
    .ant-list-item {
      padding: 12px 24px;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
      &.selected,
      &:hover {
        background-color: #dfffed;
        font-weight: 600;
      }
    }
  }
}