@import "../theme";

.consultation-container {
  @media @desktop {
    padding: 80px 140px;
    margin: 0 -140px;
  }
  @media @mobile {
    padding: 24px / @pv 32px / @pv;
  }
  h1 {
    font-size: 48px;
    line-height: 58px;
    @media @mobile {
      font-size: 28px / @pv;
      line-height: 42px / @pv;
    }
  }
  & > h2 {
    width: 670px;
    margin-bottom: 36px;
    font-size: 24px;
    font-weight: normal !important;
    font-family: Roboto;
    color: #696969;
    @media @mobile {
      width: 100%;
      font-size: 16px / @pv;
      line-height: 26px / @pv;
      margin-bottom: 32px / @pv;
    }
  }
  .dev-evt-tip{
    width: 50%;
    color: #696969;
    margin-bottom: 12px;
    @media @mobile{
      width: 100%;
    }
  }
  @media @mobile {
    & > .flex {
      flex-flow: column;
    }
  }
  .card-wrap {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    padding: 32px;
    @media @mobile {
      width: 100%;
      margin-bottom: 24px / @pv;
      .card-wrap {
        padding: 16px / @pv;
      }
    }
    &.left-wrap {
      flex: 1 0 477px;
      margin-right: 60px;
      .field-form-items {
        width: 100%;
      }
      @media @mobile {
        flex: 1 0 100%;
      }
      .ant-radio-group {
        @media @desktop {
          margin-bottom: 24px;
        }
        @media @mobile {
          width: 100%;
        }
        label {
          padding: 15px 24px;
          height: auto;
          line-height: 1;

          @media @desktop {
            margin-right: 16px;
          }
          span:last-child {
            display: flex;
            align-items: center;
            svg {
              margin-right: 8px;
            }
          }
          &.ant-radio-button-wrapper-checked {
            &:before {
              width: 0;
            }
            border: 2px solid #00ff6d;
            background: #dfffed;
            color: #000;
            font-weight: 900;
          }
          @media @mobile {
            width: calc(50% - 11px);
            padding: 16px / @pv 0;
            text-align: center;
            &:not(:nth-of-type(3n)) {
              margin: 0 16px / @pv 16px / @pv 0 !important;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              text-align: center;
            }
          }
        }
        @media @mobile {
          &.frequency-radio {
            label {
              width: auto;
              flex: 0 0 auto;
              padding: 16px / @pv;
              &:last-child {
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
    &.right-wrap {
      flex: 0 0 480px;
      h3 {
        margin-bottom: 24px;
      }
      p.flex {
        align-items: flex-start;
        text-align: right;
        span {
          flex: 0 0 180px;
          text-align: left;
        }
      }
      a {
        color: #000;
        text-transform: uppercase;
        font-weight: 900;
        text-decoration: underline;
      }
      .field-form-items {
        width: 100%;
        padding-right: 16px;
      }
    }
  }
}
