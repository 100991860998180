.sponsorship-review {
  padding: 35px 52px;
  width: 100%;
  color: #3b5266;
  background-color: #f1f1f6;
  font-size: 16px;

  .top-bar {
    .status-tag {
      margin: 0px auto 0px 10px;
    }

    .action {
      border: 1px solid #304659;
      padding: 0;
      cursor: pointer;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      color: #000000;
    }
  }

  .title {
    font-size: 45px;
    font-weight: 900;
    color: #304659;
    margin-bottom: 0px !important;
  }

  .des {
    margin-top: 18px;
    margin-bottom: 40px;
    color: #3b5266;
    max-width: 670px;
  }

  .sub-title {
    margin: 30px 0 20px;
  }

  .review-card-container {
    display: none;

    &.show {
      display: block;
    }
  }

  .card-wrap {
    flex: 1;
    padding: 0 32px 32px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  }

  .ant-form.ant-form-vertical {
    display: none;
    background-color: #fff;
    padding: 0 32px 32px;
    border: 1px solid #e6e6e6;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    &.show {
      display: block;
    }

    .textarea-wrap {
      textarea {
        min-height: 98px !important;
      }
    }

    .row {}
  }

  .ant-col.ant-form-item-label {
    margin-bottom: 8px;
  }

  .btn-approve {
    margin-left: 30px;
  }

  .flex.end {
    margin-top: 24px;

    .back-component-container {
      margin-bottom: 0px;
    }

    .btn-draft {
      margin-top: 0px;
      margin-left: 16px;
      height: 56px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  .application-subsidized-social-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .application-subsidized-social-note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #313335;
  }

  .application-subsidized-fiscal-premium-note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.25);
    margin-top: -10px;
    margin-bottom: 10px;
  }


  .upload-item-wrap {
    width: 100%;
    margin-bottom: 24px;

    .ant-upload-select {
      width: 100%;
    }

    .children-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      // background: #f1f1f6;
      background: #fff;
      border: 1px dashed #e0e0e0;
      padding: 24px;
      cursor: pointer;

      .ant-upload-drag-icon {
        color: #00ff6d;
        font-size: 32px;
        margin-bottom: 16px;
      }

      .ant-upload-text {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .ant-upload-hint {
        color: #696969;
      }
    }

    .ant-upload-list {
      .ant-upload-list-item {
        background: #fff;
      }

      button {
        border: none;
      }
    }
  }
}