@import "../theme";

.sponsor-ship-container {
  h4 {
    width: 545px;
    margin-bottom: 56px;

    @media @mobile {
      width: 100%;
    }
  }

  .sponsorship-wrap {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    padding: 24px 32px 0 32px;

    @media @mobile {
      padding: 24px / @pv;
    }

    h2 {
      margin-bottom: 0 !important;

      @media @mobile {
        min-width: 100%;
      }
    }

    .ant-tag {
      height: 24px;
      line-height: 22px;
      margin-left: 16px;
    }

    .btn-span {
      @media @mobile {
        width: 100%;
        margin-top: 10px;

        .ant-btn {
          width: 100%;
        }
      }
    }

    .sponsorship-content {
      border-top: 1px solid #f0f0f0;
      margin: 24px -32px 0;
      padding: 0 32px;
      position: relative;

      @media @mobile {
        flex-direction: column;
        padding: 0;
        margin: 24px / @pv -24px / @pv 0;
      }

      &>.flex {
        padding: 24px;
        height: 100%;
        flex: 1;

        &:first-child {
          padding-left: 0;

          @media @mobile {
            padding-left: 24px;
          }

          &:after {
            position: absolute;
            top: 0;
            right: 51.35%;
            bottom: 0;
            content: "";
            display: block;
            width: 1px;
            background-color: #f0f0f0;

            @media @mobile {
              display: none;
            }
          }
        }
      }

      h4 {
        font-weight: 700;
        width: auto;
      }

      .anticon {
        color: #05a5ff;
        font-weight: bold;
        margin-right: 18px;
        margin-top: 4px;
      }

      .flex>span:not(.anticon) {
        color: #696969;
      }

      &.view-application {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
        letter-spacing: 1.4px;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin-top: 0px;
        cursor: pointer;

        .anticon-right {
          color: #797F84;
          font-weight: bold;
          margin-top: 0px;
          top: 50%;
          right: 10px;
          position: absolute;
          transform: translateY(-50%);

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }


  }

  .table-wrap {
    width: 100%;
  }

  @media @mobile {
    .ant-steps .ant-steps-item-finish {
      flex: 0 0 60px;

      &:not(:first-child) {
        padding-left: 10px !important;
      }
    }

    .ant-steps .ant-steps-item-finish:not(:last-child) {
      .ant-steps-item-title {
        visibility: hidden;
      }

      .ant-steps-item-content {
        position: relative;

        &:after {
          position: absolute;
          top: 16px;
          left: 0;
          display: block;
          width: 9999px;
          height: 1px;
          background: #f0f0f0;
          content: "";
        }
      }
    }
  }
}

.application-container,
.agreement-container,
.summary-container {
  h2 {
    font-size: 32px;
  }

  .ant-steps {
    width: 482px;

    @media @mobile {
      width: 100%;
    }
  }

  .show {
    padding: 32px 32px 8px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    .flex {
      .ant-form-item {
        flex: 1;

        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 40px;
    padding-top: 24px;
    border-top: 1px solid #f0f0f0;
    text-align: right;

    .back-component-container {
      margin-bottom: 0;

      @media @mobile {
        width: 45%;
        text-align: center;
      }
    }

    button {
      height: 56px;

      @media @mobile {
        width: 45%;
      }
    }
  }

  .right-item {
    position: sticky;
    top: 0;
  }

  .application-continue-btn {
    @media @mobile {
      width: 100%;
    }
  }

  .tips {

    h3>span,
    i {
      color: red;
    }
  }


  .application-subsidized-social-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .application-subsidized-social-note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #313335;
  }

  .radio-wrap {
    margin-top: 10px;

    .radio-selection {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .ant-form .ant-form-item.textarea-wrap {
    .textarea-wrap-length-medium {
      min-height: 52px !important;
    }
  }

  .application-subsidized-fiscal-premium-note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.25);
    margin-top: -5px;
    margin-bottom: 10px;
  }

}

.summary-container,
.change-payment-container {

  .ant-radio-group,
  .ant-radio-wrapper {
    width: 100%;
  }

  .radio-wrap {
    display: flex;
    justify-content: space-between;

    &.ant-radio-group {
      margin-bottom: 24px;

      label {
        flex: 1;
        margin-right: 16px;
        padding: 15px 24px;
        height: auto;
        line-height: 1;

        &.ant-radio-button-wrapper-checked {
          &:before {
            width: 0;
          }

          border: 2px solid #00ff6d;
          background: #dfffed;
          color: #000;
          font-weight: 900;
        }

        &>span:last-child {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          svg {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .card-wrap {
    padding: 32px;
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    h3 {
      font-size: 24px;
      font-weight: 700;
    }

    h4 {
      font-size: 16px;
      font-weight: 700;
    }

    p {
      .anticon {
        color: #05a5ff;
        margin-top: 3px;
        margin-right: 18px;
        font-weight: 900;

        &+span {
          color: #696969;
        }
      }
    }

    .ant-radio-group {
      label {
        max-width: 506px;
        padding: 24px;
        border: 1px solid #c7c7c7;

        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 16px;

        span:nth-of-type(2) {
          width: 100%;
        }

        @media @mobile {
          padding: 16px / @pv;
        }

        &.ant-radio-wrapper-checked {
          background-color: #dfffed;
          border: 3px solid #00ff6d;

          .ant-radio-checked {
            .ant-radio-inner {
              border-color: #05a5ff;
            }

            &::after {
              border-color: #05a5ff;
            }
          }

          .ant-radio-inner::after {
            background-color: #05a5ff;
          }

          .text {
            display: block;
          }

          p.flex {
            font-weight: bold;
            color: #000;
          }
        }

        .ant-radio {
          margin-right: 16px;
          height: 16px;
        }

        p.flex {
          color: #696969;
          line-height: 1;
          margin-bottom: 0;

          &.disabled-true {
            color: #DDDFE4;
          }

          i:nth-of-type(2) {
            font-weight: bold;
            color: #000;
            text-align: right;
            line-height: 24px;
          }

          time {
            margin-left: 4px;
            font-size: 12px;
            font-weight: normal;
            color: #696969;
          }

          .price-warp {
            display: flex;

            &.disabled-true {
              color: #DDDFE4;
            }

            .old-price {
              font-weight: 400;
              font-size: 16px;
              line-height: 26px;
              text-decoration-line: line-through;
              text-align: center;
              color: #797F84;
            }
          }



        }

        .text {
          display: none;
          margin-bottom: 0;
          margin-top: 16px;
          font-size: 12px;
          line-height: 20px;
          color: #696969;
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }

  .right-item {
    padding-left: 40px;

    @media @mobile {
      padding-left: 0;
    }

    .card-wrap {
      p.flex {
        span {
          color: #a0a0a0;
        }

        b {
          font-weight: 700;
          font-size: 24px;
        }
      }

      .tips {
        font-size: 12px;
        color: #696969;
        margin-bottom: 32px;
        line-height: 20px;

        a {
          font-weight: 700;
          font-size: 12px;
          text-transform: uppercase;
          color: #000;
          text-decoration: underline;
        }
      }
    }

    .field-form-items {
      width: 100%;
      padding-right: 16px;
    }
  }
}

.overview-membership-container {
  .card-wrap {
    flex: 1;
    padding: 32px;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    &:not(:first-child) {
      margin-left: 24px;

      @media @mobile {
        margin-left: 0;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
    }

    h2 {
      font-size: 32px;
      font-weight: 900;
    }

    p {
      .anticon {
        color: #05a5ff;
        margin-top: 3px;
        margin-right: 18px;
        font-weight: 900;

        &+span {
          color: #696969;
        }
      }
    }
  }

  .left-item {

    a,
    span.cancel-span {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 900;
      font-family: RobotoBold;
      color: #000;
      text-decoration: underline;
      cursor: pointer;

      &.disabled {
        color: #a0a0a0;
      }
    }

    .tips {
      font-size: 12px;
      color: #696969;
    }
  }

  .right-item {
    .field-form-items {
      width: 100%;
    }

    #payment-form {
      &>.flex>.field-form-items {
        margin-bottom: 0px;
      }
    }

    .flex>.ant-form-item {
      flex: 1;

      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  .search-container {
    &>svg {
      margin-left: 0;
    }
  }

  .table-item {
    background-color: #fff;
    border: 0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    @media @mobile {
      width: 100%;
    }

    .ant-table {
      border: 0;
    }

    .ant-pagination {
      margin-right: 32px;
    }
  }

  .table-wrap {
    width: 100%;
  }
}

.overview-detail-membership-container {
  h1 {
    margin-bottom: 32px !important;

    @media @mobile {
      word-break: break-all;
    }
  }

  &>.flex {
    width: 100%;

    @media @mobile {
      flex-direction: column;
    }

    .card-wrap:not(:first-child) {
      margin-left: 40px;

      @media @mobile {
        margin-left: 0;
      }
    }
  }

  .card-wrap {
    width: 100%;
    flex: 1;
    padding: 32px;
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    p {
      align-items: flex-start;
      color: #696969;

      span {
        color: #000;
        display: block;
        max-width: 60%;
        text-align: right;
        word-break: break-all;
      }
    }

    h3 {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}

.change-payment-container {
  position: relative;
  max-width: 585px;

  .card-wrap {
    padding: 24px;

    &>p {
      color: #696969;
      margin-bottom: 8px;
    }
  }
}

.setting-membership-container {
  .card-wrap {
    width: 100%;
    padding: 32px 32px 16px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  }

  p {
    margin-top: 24px;
    width: 100%;
  }
}

.agreement-container {
  h2 {
    font-size: 40px;
    line-height: 50px !important;
    margin-bottom: 40px !important;
  }

  .ant-steps {
    width: 644px;

    @media @mobile {
      width: 100%;

      .ant-steps-item-wait,
      .ant-steps-item-finish {
        flex: 0.4;

        .ant-steps-item-title {
          display: none;
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 24px;

    button {
      &:only-child {
        margin-left: auto;

        @media @mobile {
          width: 100%;
        }
      }
    }
  }

  .select-container {
    padding-bottom: 3px !important;
  }

  .identification-items {
    .ant-form-item {
      margin-left: 0 !important;
    }
  }
}