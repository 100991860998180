.staff-scheduling {
  padding: 40px 0px 0px 40px;

  .ant-steps-item {
    margin: 0px 8px;
    width: 160px;
    padding-left: 0px !important;
  }

  .step-card {
    border: 1px solid #dddfe4;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    background-color: white;
  }

  .steps-content {
    .selector-container {
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        box-shadow: 4px 4px 14px 0px #00000014;
        font-weight: bold;
      }
      &.selected {
        border: 1px solid #10ac57;
        box-shadow: 4px 4px 14px 0px #00000014;
        font-weight: bold;
      }
    }

    width: 100%;
    .step-card;
    .steps-content-head {
      .steps-content-head-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
      }
      height: 80px;
      border-bottom: 1px solid #dddfe4;
      display: flex;
      align-items: center;
      padding-left: 32px;
      padding-right: 56px;
      .steps-action {
        margin-left: auto;
      }
    }

    .topic-list {
      display: flex;
      flex-flow: row wrap;
      padding: 20px 3px 50px 3px;

      .topic-card {
        padding: 16px 20px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 238px;
        height: 124px;
        border: 1px solid #dddfe4;
        flex: none;
        margin: 15px;
        font-family: "Roboto";
        font-size: 20px;
        color: #313335;
        position: relative;
        .topic-card-content {
          display: block;
          &::after {
            content: "";
            position: absolute;
            inset: 0;
          }
        }
        .selector-container;
      }
    }

    .pick-staff-step {
      padding: 20px;
      .staff-list {
        .staff-item {
          width: 337px;
          .selector-container;
          padding: 32px 27px;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #4a4a4a;
          background: #ffffff;
          border: 1px solid #dddfe4;
          .avatar {
            height: 200px;
            width: 100%;
          }
          .name {
            margin-top: 16px;
            font-weight: 700;
            font-size: 24px;
            line-height: 38px;
            color: #000000;
          }
          .email {
            margin-top: 8px;
          }
          .description {
            margin-top: 8px;
          }
          .specializing {
            margin-top: 16px;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
          }
          .topic-tag {
            display: flex;
            margin-top: 22px;
            .ant-tag {
              padding: 2px 8px;
              background: #efffed;
              border: 1px solid #7dffbe;
              border-radius: 4px;
            }
          }
          .request-btn {
            margin-top: 48px;
            width: 100%;
          }
        }
      }
      .pick-duration {
        display: flex;
        margin-bottom: 54px;
        .duration-option {
          .selector-container;
          width: 237px;
          height: 154px;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          border: 1px solid #dddfe4;
          margin-right: 30px;
          position: relative;
          .duration-option-inner {
            display: block;
            &::after {
              content: "";
              position: absolute;
              inset: 0;
            }
          }

          &.selected {
            .duration-time {
              font-weight: 700;
            }
            .duration-fee {
              color: #10ac57;
            }
          }
          .duration-time {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #313335;
          }
          .duration-fee {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
        }
      }
      .section-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        color: #000000;
        margin-top: 54px;
        margin-bottom: 20px;
      }
    }
  }

  .consultation-payment {
    .summary-item {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      line-height: 26px;
      margin-bottom: 16px;
      .item-title {
        color: #313335;
      }
      .item-content {
        color: #000000;
        text-align: right;
      }
    }

    .payment-tips {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #313335;
      margin-bottom: 0px;

      a {
        font-weight: 900;
        color: black;
        letter-spacing: 1px;
        &:hover {
          color: black;
          text-decoration: underline;
          opacity: 0.8;
        }
      }
    }
  }

  .custom-select-render.filter-by-admin {
    .ant-select-selector {
      padding-left: 147px !important;
    }
  }

  .custom-select-render.filter-by-duration {
    .ant-select-selector {
      padding-left: 117px !important;
    }
  }

  .custom-select-render.filter-by-topic {
    .ant-select-selector {
      padding-left: 98px !important;
    }
  }

  .custom-select-render.filter-by-status {
    .ant-select-selector {
      padding-left: 100px !important;
    }
  }

  .step-content-container {
    min-height: 300px;
    max-height: calc(100vh - 375px);
    overflow-y: auto;
  }

  .ant-table-thead {
    .ant-table-cell {
      white-space: nowrap;
    }
  }

  .ant-pagination-total-text {
    position: absolute;
    left: 32px;
    color: #000;
    font-weight: 700;
  }
}
