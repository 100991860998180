@import '@/styles/theme.less';

.my-favorite-container {
    position      : relative;
    height        : 100%;
    display       : flex;
    flex-direction: column;

    h1 {
        font-size    : 45px;
        font-weight  : 900;
        margin-bottom: 30px;
    }

    .tabs {
        background: #ffffff;
        display   : flex;
        width     : 100%;
        overflow: hidden;
        .tab-btn {
            width          : 90px;
            height         : 56px;
            // flex: 1 0;
            display        : flex;
            justify-content: center;
            align-items    : center;
            border-bottom  : 2px solid transparent;
            cursor         : pointer;
            color          : #696969;

            &.actived {
                border-bottom-color: #00FF6D;
                font-weight        : bold;
            }
        }

        .ant-form-horizontal {
            width      : 250px;
            height     : 40px;
            margin-left: auto;
            @media @mobile {
                width: 100px / @pv;
            }

            * {
                height      : 100%;
                border-width: 0;
            }
            .ant-input-group-addon {
                background-color: #ffffff;
            }
        }
    }

    .favorite-list {
        // flex: 2;
        width     : 100%;
        color     : @primary-dark;
        background: #ffffff;

        .ant-table-cell {

            .user {
                flex: 1;
                display: flex;
                align-items: center;

                .avatar {
                    width        : 32px;
                    height       : 32px;
                    border-radius: 50%;
                    overflow     : hidden;
                    margin-right : 8px;
                }

                .title {
                    display: inline-block;
                }
            }

            .info {
                flex         : 2;
                overflow     : hidden;
                white-space  : nowrap;
                text-overflow: ellipsis;
            }

            .date {
                flex: 1;
            }

            .actions {
                width      : 40px;
                text-align : center;
                font-size  : 20px;
                font-weight: bold;
            }
        }
    }
}