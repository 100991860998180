@import '../../styles/theme.less';

.app-container {
    .admin-layout-container {

        &.flex.mobile {
            display: block;

            header.home-wrap.mobile {
                height     : 64px;
                align-items: center;
            }
        }

    }
}

// dashboard-header-mobile
.ant-drawer.dashboard-header-mobile-menu {
    width: 100vw;

    .ant-drawer-body {
        padding: 0;

        .menu-layout-container {
            .ant-menu {
                width: 100% !important;
            }
        }

        // mixin
        .line-item {
            width      : 100%;
            display    : block;
            height     : 48px;
            line-height: 48px;
            padding    : 0 0 0 64px;
            text-align : left;
            box-shadow : none;
            color      : #696969;

            &.active,
            &:hover {
                background-color: #dfffed;
                font-weight     : 900;
                font-family     : RobotoBold;
                color           : #000;
                cursor          : pointer;
            }
        }

        .ant-collapse {
            border          : none;
            background-color: #ffffff;



            .ant-collapse-header {
                padding: 16px 40px;

                .user-wrap {
                    .avatar {
                        width        : 24px;
                        height       : 24px;
                        border-radius: 50%;
                        overflow     : hidden;
                        margin-right : 16px;
                    }
                }
            }

            .ant-collapse-content {
                .ant-collapse-content-box {
                    padding: 0;

                }

                a,
                span {
                    .line-item()
                }

            }
        }

        .btm-menu-item {
            .line-item();
            padding-left: 40px;
        }
    }

}