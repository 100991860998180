@import "../theme";

.artist-container {
  .artist-chart {
    padding: 24px;
    margin-top: 24px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    .chart-title {
      color: #696969;
      line-height: 28px;
      margin-bottom: 0;
      span {
        font-size: 12px;
        color: #a0a0a0;
        &:first-child {
          font-size: 16px;
        }
        button {
          margin-left: 8px;
          height: 38px;
          width: 40px;
          padding: 0;
          min-width: 0;
          border-color: #aaa;
          span {
            color: #000;
          }
        }
      }
    }
    .ant-divider-horizontal {
      margin-bottom: 40px;
    }
    h2 {
      p {
        display: inline-block;
        margin-left: 24px;
        color: #70c861;
        font-size: 16px;
        margin-bottom: 0;
        i {
          color: #a0a0a0;
        }
      }
    }
  }
  .artist-list {
    margin-top: 36px;
    .artist-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding: 24px;
      border: 1px solid #e0e0e0;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
      background-color: #fff;
      .ant-dropdown-link {
        cursor: pointer;
        width: 48px;
        line-height: 48px;
        height: 48px;
        margin-right: 16px;
        border: 1px solid #000;
        display: inline-block;
        font-size: 26px;
        color: #000;
        text-align: center;
      }
      img {
        width: 130px;
        height: 130px;
        flex: 0 0 130px;
        margin-right: 24px;
        border-radius: 130px;
      }
      h3 {
        margin-bottom: 0;
        font-size: 32px;
        line-height: 40px;
      }
      .ul-wrap ul {
        margin-right: 12px;
      }
      .ant-tag {
        margin-left: 8px;
      }
      .artist-item-info {
        flex: 0 0 calc(100% - 154px);
      }
      p {
        width: 100%;
        span {
          font-size: 12px;
          &.artist-time {
            color: #a0a0a0;
          }
        }
      }
      ul {
        padding-left: 18px;
        color: #696969;
        margin-bottom: 0;
      }
      .btn-wrap {
        svg {
          width: 24px;
          height: 24px;
          margin-top: 6px;
        }
        button {
          height: 56px;
          margin-left: 16px;
          padding: 0 16px;
        }
      }
    }
  }
  @media @mobile {
    .field-form-items {
      width: 100%;
    }
    .artist-list {
      .artist-item {
        justify-content: flex-start;
        flex-flow: column;
        align-items: flex-start;
        position: relative;
        .artist-item-info {
          width: 100%;
          & > .align-end {
            margin-top: 16px / @pv;
            .artist-time {
              position: absolute;
              bottom: 96px / @pv;
            }
          }
          .flex {
            display: flex;
            justify-content: flex-start;
            flex-flow: column;
            align-items: flex-start;
            width: 100%;
            .btn-wrap {
              width: 100%;
              margin-top: 54px / @pv;
              flex-flow: row;
              justify-content: flex-end;
              align-items: flex-end;
            }
          }
        }
      }
    }
  }
}
