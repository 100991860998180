@import "../theme";

.grant-container {
  .step-container {
    padding-top: 20px;
    .step-wrap {
      p {
        max-width: 770px;
        text-align: center;
        width: 100%;
      }
      .ant-steps {
        width: auto;
        display: flex !important;
        &.ant-steps-dot {
          .ant-steps-item-content {
            width: 232px;
            .ant-steps-item-description {
              width: 100%;
              white-space: pre-wrap;
            }
          }
          .ant-steps-item-tail,
          .ant-steps-item-icon {
            margin-left: 114px;
          }
        }
      }
    }
  }
  .campaign-on-wrap .campaign-list .campaign-info-wrap .info-title {
    flex: 0 0 52%;
  }
  .btn {
    button:last-child {
      margin-left: 16px;
    }
  }
}

.grant-info-modal {
  p {
    width: 100%;
    &.flex {
      text-align: left;
      .anticon {
        color: #05a5ff;
        font-weight: bold;
        margin-right: 18px;
        margin-top: 4px;
        margin-left: 10px;
      }
    }
  }
}

.grant-confirm-modal {
  .ant-modal-title {
    font-size: 24px;
    font-weight: 900;
    font-family: RobotoBold;

    padding: 8px 0;
  }
  .ant-modal-body {
    font-size: 16px;
    line-height: 26px;
    color: #696969;
  }
}

.grant-payment-container {
  h1 {
    margin-bottom: 40px !important;
  }
  & > .flex {
    width: 100%;
  }
  .ant-breadcrumb {
    &>span {
      .ant-breadcrumb-link {
        @media @mobile {
          margin-bottom: 6px;
        }
      }
    }
  }
  .card-wrap {
    flex: 1;
    padding: 32px;
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    &:first-child {
      margin-right: 40px;
      @media @mobile {
        margin-right: 0;
      }
    }
    h4 {
      font-weight: 900;
      color: #000;
    }
    p.flex {
      span {
        &:first-child {
          color: #a0a0a0;
        }
      }
    }
    .tips {
      font-size: 12px;
    }
    .field-form-items {
      width: 100%;
      padding-right: 16px;
    }
  }
}

.step-container {
  @media @mobile {
    width: 100% !important;
    padding: 30px / @pv;
  }
}