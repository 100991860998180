@import "../theme";

.donation-history-container {
  h1 {
    margin-bottom: 40px !important;
    @media @mobile {
      flex-direction: column;
      align-items: flex-start;
      button {
        margin-top: 16px / @pv;
      }
    }
  }
  .search-wrap {
    margin-bottom: 24px;
    .field-form-items {
      margin-right: 16px;
    }
  }

  .table-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    //box-shadow: 4px 4px 14px rgb(0 0 0 / 8%);
    .ant-table {
      border: 0;
    }
    .ant-pagination {
      padding: 0 32px;
      .ant-pagination-total-text {
        position: absolute;
        left: 16px;
        color: #000;
        font-weight: 700;
      }
    }
  }

  .filter-item {
    border: 1px solid #304659;
    padding: 0;
    cursor: pointer;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  @media @mobile {
    .search-wrap {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      .flex {
        width: 100%;
        flex-direction: column;
        .field-form-items {
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px / @pv;
        }
      }
    }
  }
}
